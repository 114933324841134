import React, { useState, useEffect, useRef } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { playerTypes } from "./PlayerTypes.js";

const PlayerEditPanel = ({ player, onSave, onCancel }) => {
  const [nickname, setNickname] = useState(player.nickname);
  const [translatedNickname, setTranslatedNickname] = useState(
    player.translatedNickname
  );
  const [descriptiveNickname, setDescriptiveNickname] = useState(
    player.descriptiveNickname
  );
  const [playerType, setPlayerType] = useState(player.playerType);
  const [notes, setNotes] = useState(player.notes || []);
  const [newNote, setNewNote] = useState("");

  // Main image state
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  // HUD 6 Max image state
  const [selectedFileHud6max, setSelectedFileHud6max] = useState(null);
  const [previewUrlHud6max, setPreviewUrlHud6max] = useState(null);
  // HUD 8 Max image state
  const [selectedFileHud8max, setSelectedFileHud8max] = useState(null);
  const [previewUrlHud8max, setPreviewUrlHud8max] = useState(null);

  // File input refs
  const fileInputRef = useRef(null);
  const fileInputRefHud6 = useRef(null);
  const fileInputRefHud8 = useRef(null);

  // Generate preview URL for Main image
  useEffect(() => {
    let objectUrl;
    if (selectedFile) {
      objectUrl = URL.createObjectURL(selectedFile);
      setPreviewUrl(objectUrl);
    } else {
      setPreviewUrl(null);
    }
    return () => {
      if (objectUrl) URL.revokeObjectURL(objectUrl);
    };
  }, [selectedFile]);

  // Generate preview URL for HUD 6 Max image
  useEffect(() => {
    let objectUrl;
    if (selectedFileHud6max) {
      objectUrl = URL.createObjectURL(selectedFileHud6max);
      setPreviewUrlHud6max(objectUrl);
    } else {
      setPreviewUrlHud6max(null);
    }
    return () => {
      if (objectUrl) URL.revokeObjectURL(objectUrl);
    };
  }, [selectedFileHud6max]);

  // Generate preview URL for HUD 8 Max image
  useEffect(() => {
    let objectUrl;
    if (selectedFileHud8max) {
      objectUrl = URL.createObjectURL(selectedFileHud8max);
      setPreviewUrlHud8max(objectUrl);
    } else {
      setPreviewUrlHud8max(null);
    }
    return () => {
      if (objectUrl) URL.revokeObjectURL(objectUrl);
    };
  }, [selectedFileHud8max]);

  // Generalized image change handler
  const handleImageChange = (eventOrFile, setter) => {
    let file;
    if (eventOrFile instanceof File) {
      file = eventOrFile;
    } else {
      file = eventOrFile.target.files[0];
    }
    // Validate file type and size
    if (file && !file.type.startsWith("image/")) {
      alert("Please select an image file");
      return;
    }
    if (file && file.size > 5 * 1024 * 1024) {
      alert("File size should be less than 5MB");
      return;
    }
    setter(file);
  };

  const handleClickMain = () => {
    fileInputRef.current.click();
  };
  const handleClickHud6 = () => {
    fileInputRefHud6.current.click();
  };
  const handleClickHud8 = () => {
    fileInputRefHud8.current.click();
  };

  const handleAddNote = () => {
    if (!newNote.trim()) return;
    setNotes([...notes, newNote.trim()]);
    setNewNote("");
  };

  const handleRemoveNote = (index) => {
    const updatedNotes = [...notes];
    updatedNotes.splice(index, 1);
    setNotes(updatedNotes);
  };

  const handleSave = () => {
    // Create updated player object including new images if they were selected.
    const updatedPlayer = {
      ...player,
      nickname,
      translatedNickname,
      descriptiveNickname,
      playerType,
      notes,
      // Use keys that your API expects for updated images
      ...(selectedFile ? { newImage: selectedFile } : {}),
      ...(selectedFileHud6max ? { newImageHud6max: selectedFileHud6max } : {}),
      ...(selectedFileHud8max ? { newImageHud8max: selectedFileHud8max } : {}),
    };
    onSave(updatedPlayer);
  };

  const handleCancel = () => {
    onCancel();
  };

  // Close the panel on Escape key press
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        handleCancel();
      }
    };
    window.addEventListener("keydown", handleEsc);
    return () => window.removeEventListener("keydown", handleEsc);
  }, []);

  return (
    <Box sx={{ p: 2, borderLeft: "1px solid #ddd", height: "100%" }}>
      <Typography variant="h6">Edit Player</Typography>

      {/* Note Input and List */}
      <Box display="flex" alignItems="center">
        <TextField
          autoFocus
          margin="dense"
          label="New Player Note"
          type="text"
          fullWidth
          variant="outlined"
          value={newNote}
          onChange={(e) => setNewNote(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter" && newNote.trim()) {
              handleAddNote();
            }
          }}
        />
        <Button
          onClick={handleAddNote}
          color="primary"
          variant="contained"
          sx={{ ml: 1, height: "fit-content" }}
          disabled={!newNote.trim()}
        >
          Add Note
        </Button>
      </Box>

      {notes.length > 0 && (
        <Box mt={4}>
          <Typography variant="h6">Notes:</Typography>
          {notes.map((note, index) => (
            <Box
              key={index}
              mt={2}
              p={2}
              border="1px solid #ccc"
              borderRadius="5px"
              position="relative"
            >
              <Typography variant="body1">{note}</Typography>
              <IconButton
                onClick={() => handleRemoveNote(index)}
                color="secondary"
                size="small"
                sx={{
                  position: "absolute",
                  top: 5,
                  right: 5,
                  backgroundColor: "rgba(255,255,255,0.7)",
                  "&:hover": { backgroundColor: "rgba(255,255,255,1)" },
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
        </Box>
      )}

      <TextField
        label="Nickname"
        fullWidth
        margin="normal"
        value={nickname}
        onChange={(e) => setNickname(e.target.value)}
      />
      <TextField
        label="Translated Nickname"
        fullWidth
        margin="normal"
        value={translatedNickname}
        onChange={(e) => setTranslatedNickname(e.target.value)}
      />
      <TextField
        label="Descriptive Nickname"
        fullWidth
        margin="normal"
        value={descriptiveNickname}
        onChange={(e) => setDescriptiveNickname(e.target.value)}
      />

      <FormControl fullWidth margin="dense">
        <InputLabel id="playertype-label">Player Type</InputLabel>
        <Select
          labelId="playertype-label"
          value={playerType}
          label="Player Type"
          onChange={(e) => setPlayerType(e.target.value)}
        >
          {playerTypes.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Main Image Upload Section */}
      <Box
        onClick={handleClickMain}
        sx={{
          mt: 2,
          p: 2,
          border: "2px dashed",
          borderColor: "primary.main",
          borderRadius: "5px",
          textAlign: "center",
          cursor: "pointer",
          bgcolor: "background.paper",
          "&:hover": { bgcolor: "action.hover" },
        }}
      >
        <Typography variant="body1" color="textSecondary">
          Drag and drop or click to select Main Image
        </Typography>
        {previewUrl && (
          <Box mt={2} position="relative">
            <img
              src={previewUrl}
              alt="Main Preview"
              style={{ maxWidth: "100%", marginTop: "10px" }}
            />
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setSelectedFile(null);
              }}
              color="secondary"
              size="small"
              sx={{
                position: "absolute",
                top: 5,
                right: 5,
                backgroundColor: "rgba(255,255,255,0.7)",
                "&:hover": { backgroundColor: "rgba(255,255,255,1)" },
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        )}
      </Box>
      <input
        accept="image/*"
        type="file"
        onChange={(e) => handleImageChange(e, setSelectedFile)}
        style={{ display: "none" }}
        ref={fileInputRef}
      />

      {/* HUD 6 Max Image Upload Section */}
      <Box
        onClick={handleClickHud6}
        sx={{
          mt: 2,
          p: 2,
          border: "2px dashed",
          borderColor: "primary.main",
          borderRadius: "5px",
          textAlign: "center",
          cursor: "pointer",
          bgcolor: "background.paper",
          "&:hover": { bgcolor: "action.hover" },
        }}
      >
        <Typography variant="body1" color="textSecondary">
          Drag and drop or click to select HUD 6 Max Image
        </Typography>
        {previewUrlHud6max && (
          <Box mt={2} position="relative">
            <img
              src={previewUrlHud6max}
              alt="HUD 6 Max Preview"
              style={{ maxWidth: "100%", marginTop: "10px" }}
            />
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setSelectedFileHud6max(null);
              }}
              color="secondary"
              size="small"
              sx={{
                position: "absolute",
                top: 5,
                right: 5,
                backgroundColor: "rgba(255,255,255,0.7)",
                "&:hover": { backgroundColor: "rgba(255,255,255,1)" },
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        )}
      </Box>
      <input
        accept="image/*"
        type="file"
        onChange={(e) => handleImageChange(e, setSelectedFileHud6max)}
        style={{ display: "none" }}
        ref={fileInputRefHud6}
      />

      {/* HUD 8 Max Image Upload Section */}
      <Box
        onClick={handleClickHud8}
        sx={{
          mt: 2,
          p: 2,
          border: "2px dashed",
          borderColor: "primary.main",
          borderRadius: "5px",
          textAlign: "center",
          cursor: "pointer",
          bgcolor: "background.paper",
          "&:hover": { bgcolor: "action.hover" },
        }}
      >
        <Typography variant="body1" color="textSecondary">
          Drag and drop or click to select HUD 8 Max Image
        </Typography>
        {previewUrlHud8max && (
          <Box mt={2} position="relative">
            <img
              src={previewUrlHud8max}
              alt="HUD 8 Max Preview"
              style={{ maxWidth: "100%", marginTop: "10px" }}
            />
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setSelectedFileHud8max(null);
              }}
              color="secondary"
              size="small"
              sx={{
                position: "absolute",
                top: 5,
                right: 5,
                backgroundColor: "rgba(255,255,255,0.7)",
                "&:hover": { backgroundColor: "rgba(255,255,255,1)" },
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        )}
      </Box>
      <input
        accept="image/*"
        type="file"
        onChange={(e) => handleImageChange(e, setSelectedFileHud8max)}
        style={{ display: "none" }}
        ref={fileInputRefHud8}
      />

      <Button
        variant="contained"
        color="primary"
        fullWidth
        sx={{ mt: 2 }}
        onClick={handleSave}
      >
        Save
      </Button>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        sx={{ mt: 2 }}
        onClick={handleCancel}
      >
        Cancel
      </Button>
    </Box>
  );
};

export default PlayerEditPanel;
