import React, { useRef, useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { playerTypes } from "./PlayerTypes.js";

const AddPlayerNoteModal = ({ open, handleClose }) => {
  const apiUrl = process.env.REACT_APP_SERVER_URL || "http://localhost:5000";
  const token = localStorage.getItem("token");

  // State for text fields
  const [nickname, setNickname] = useState("");
  const [translatedNickname, setTranslatedNickname] = useState("");
  const [descriptiveNickname, setDescriptiveNickname] = useState("");
  const [playertype, setPlayertype] = useState("");

  // State for notes
  const [notes, setNotes] = useState([]);
  const [newNote, setNewNote] = useState("");

  // State for image uploads
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);

  const [selectedFileHud6max, setSelectedFileHud6max] = useState(null);
  const [previewUrlHud6max, setPreviewUrlHud6max] = useState(null);

  const [selectedFileHud8max, setSelectedFileHud8max] = useState(null);
  const [previewUrlHud8max, setPreviewUrlHud8max] = useState(null);

  // File input refs
  const fileInputRef = useRef(null);
  const fileInputRefHud6max = useRef(null);
  const fileInputRefHud8max = useRef(null);

  // Create preview URLs for each image
  useEffect(() => {
    let objectUrl;
    if (selectedFile) {
      objectUrl = URL.createObjectURL(selectedFile);
      setPreviewUrl(objectUrl);
    } else {
      setPreviewUrl(null);
    }
    return () => {
      if (objectUrl) URL.revokeObjectURL(objectUrl);
    };
  }, [selectedFile]);

  useEffect(() => {
    let objectUrl;
    if (selectedFileHud6max) {
      objectUrl = URL.createObjectURL(selectedFileHud6max);
      setPreviewUrlHud6max(objectUrl);
    } else {
      setPreviewUrlHud6max(null);
    }
    return () => {
      if (objectUrl) URL.revokeObjectURL(objectUrl);
    };
  }, [selectedFileHud6max]);

  useEffect(() => {
    let objectUrl;
    if (selectedFileHud8max) {
      objectUrl = URL.createObjectURL(selectedFileHud8max);
      setPreviewUrlHud8max(objectUrl);
    } else {
      setPreviewUrlHud8max(null);
    }
    return () => {
      if (objectUrl) URL.revokeObjectURL(objectUrl);
    };
  }, [selectedFileHud8max]);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleClickHud6max = () => {
    fileInputRefHud6max.current.click();
  };

  const handleClickHud8max = () => {
    fileInputRefHud8max.current.click();
  };

  // Generalized image change handler accepts a setter function.
  const handleImageChange = (eventOrFile, setter) => {
    let file;
    if (eventOrFile instanceof File) {
      file = eventOrFile;
    } else {
      file = eventOrFile.target.files[0];
    }

    // Validate file type and size
    if (file && !file.type.startsWith("image/")) {
      alert("Please select an image file");
      return;
    }
    if (file && file.size > 5 * 1024 * 1024) {
      alert("File size should be less than 5MB");
      return;
    }
    setter(file);
  };

  const handleDrop = (event, setter) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    handleImageChange(file, setter);
  };

  const handlePaste = (event) => {
    const items = (event.clipboardData || event.originalEvent.clipboardData).items;
    for (const item of items) {
      if (item.kind === "file") {
        const file = item.getAsFile();
        // Here we paste into the main image field by default
        handleImageChange(file, setSelectedFile);
        break;
      }
    }
  };

  const handleRemoveImage = (setter) => {
    setter(null);
  };

  const handleAddNote = () => {
    if (!newNote.trim()) return;
    setNotes([...notes, newNote.trim()]);
    setNewNote("");
  };

  const handleRemoveNote = (index) => {
    const updatedNotes = [...notes];
    updatedNotes.splice(index, 1);
    setNotes(updatedNotes);
  };

  const handleSubmit = async () => {
    const formData = new FormData();

    // Append text fields
    formData.append("nickname", nickname);
    formData.append("translatedNickname", translatedNickname);
    formData.append("descriptiveNickname", descriptiveNickname);
    formData.append("playerType", playertype);

    // Append notes array
    notes.forEach((note, index) => {
      formData.append(`notes[${index}]`, note);
    });

    // Append image files if selected
    if (selectedFile) {
      formData.append("image", selectedFile);
    }
    if (selectedFileHud6max) {
      formData.append("imageHud6max", selectedFileHud6max);
    }
    if (selectedFileHud8max) {
      formData.append("imageHud8max", selectedFileHud8max);
    }

    fetch(`${apiUrl}/api/submit-player-note`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          // Reset all fields
          setNickname("");
          setTranslatedNickname("");
          setDescriptiveNickname("");
          setPlayertype("");
          setNotes([]);
          setNewNote("");
          setSelectedFile(null);
          setPreviewUrl(null);
          setSelectedFileHud6max(null);
          setPreviewUrlHud6max(null);
          setSelectedFileHud8max(null);
          setPreviewUrlHud8max(null);
          handleClose();
        } else {
          alert("Failed to submit player note");
        }
      })
      .catch((error) => {
        alert("An error occurred while submitting the player note");
        console.error(error);
      });
  };

  const handleCancel = () => {
    setNickname("");
    setTranslatedNickname("");
    setDescriptiveNickname("");
    setPlayertype("");
    setNotes([]);
    setNewNote("");
    setSelectedFile(null);
    setPreviewUrl(null);
    setSelectedFileHud6max(null);
    setPreviewUrlHud6max(null);
    setSelectedFileHud8max(null);
    setPreviewUrlHud8max(null);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleCancel} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ fontWeight: "bold", fontSize: "1.5rem", color: "primary.main" }}>
        New Player Note
      </DialogTitle>
      <DialogContent onPaste={handlePaste}>
        <TextField
          margin="dense"
          id="nickname"
          label="Nickname"
          type="text"
          fullWidth
          variant="outlined"
          value={nickname}
          onChange={(e) => setNickname(e.target.value)}
        />
        <TextField
          margin="dense"
          id="translatedNickname"
          label="Translated Nickname"
          type="text"
          fullWidth
          variant="outlined"
          value={translatedNickname}
          onChange={(e) => setTranslatedNickname(e.target.value)}
        />
        <TextField
          margin="dense"
          id="descriptiveNickname"
          label="Descriptive Nickname"
          type="text"
          fullWidth
          variant="outlined"
          value={descriptiveNickname}
          onChange={(e) => setDescriptiveNickname(e.target.value)}
        />
        <FormControl fullWidth margin="dense">
          <InputLabel id="playertype-label">Player Type</InputLabel>
          <Select
            labelId="playertype-label"
            id="playertype"
            value={playertype}
            label="Player Type"
            onChange={(e) => setPlayertype(e.target.value)}
          >
            {playerTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box display="flex" alignItems="center">
          <TextField
            autoFocus
            margin="dense"
            id="note"
            label="New Player Note"
            type="text"
            fullWidth
            variant="outlined"
            value={newNote}
            onChange={(e) => setNewNote(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && newNote.trim()) {
                handleAddNote();
              }
            }}
          />
          <Button
            onClick={handleAddNote}
            color="primary"
            variant="contained"
            sx={{ marginLeft: 1, height: "fit-content" }}
            disabled={!newNote.trim()}
          >
            Add Note
          </Button>
        </Box>

        {/* Main Image Upload */}
        <Box
          onClick={handleClick}
          onDrop={(e) => handleDrop(e, setSelectedFile)}
          onDragOver={(e) => e.preventDefault()}
          sx={{
            marginTop: "20px",
            padding: "20px",
            border: "2px dashed",
            borderColor: "primary.main",
            borderRadius: "5px",
            textAlign: "center",
            cursor: "pointer",
            bgcolor: "background.paper",
            "&:hover": { bgcolor: "action.hover" },
          }}
        >
          <Typography variant="body1" color="textSecondary">
            Drag and drop an image here, paste, or click to select (Main Image)
          </Typography>
          {selectedFile && (
            <Box mt={2} position="relative">
              <Typography variant="subtitle1" color="primary">
                Selected File: {selectedFile.name}
              </Typography>
              {previewUrl && (
                <Box mt={1} position="relative" display="inline-block">
                  <img
                    src={previewUrl}
                    alt="Preview"
                    style={{ maxWidth: "100%", marginTop: "10px" }}
                  />
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveImage(setSelectedFile);
                    }}
                    color="secondary"
                    size="small"
                    sx={{
                      position: "absolute",
                      top: 5,
                      right: 5,
                      backgroundColor: "rgba(255,255,255,0.7)",
                      "&:hover": { backgroundColor: "rgba(255,255,255,1)" },
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              )}
            </Box>
          )}
        </Box>

        {/* HUD 6 Max Image Upload */}
        <Box
          onClick={handleClickHud6max}
          onDrop={(e) => handleDrop(e, setSelectedFileHud6max)}
          onDragOver={(e) => e.preventDefault()}
          sx={{
            marginTop: "20px",
            padding: "20px",
            border: "2px dashed",
            borderColor: "primary.main",
            borderRadius: "5px",
            textAlign: "center",
            cursor: "pointer",
            bgcolor: "background.paper",
            "&:hover": { bgcolor: "action.hover" },
          }}
        >
          <Typography variant="body1" color="textSecondary">
            Drag and drop an image here, paste, or click to select (HUD 6 Max)
          </Typography>
          {selectedFileHud6max && (
            <Box mt={2} position="relative">
              <Typography variant="subtitle1" color="primary">
                Selected File: {selectedFileHud6max.name}
              </Typography>
              {previewUrlHud6max && (
                <Box mt={1} position="relative" display="inline-block">
                  <img
                    src={previewUrlHud6max}
                    alt="Preview"
                    style={{ maxWidth: "100%", marginTop: "10px" }}
                  />
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveImage(setSelectedFileHud6max);
                    }}
                    color="secondary"
                    size="small"
                    sx={{
                      position: "absolute",
                      top: 5,
                      right: 5,
                      backgroundColor: "rgba(255,255,255,0.7)",
                      "&:hover": { backgroundColor: "rgba(255,255,255,1)" },
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              )}
            </Box>
          )}
        </Box>

        {/* HUD 8 Max Image Upload */}
        <Box
          onClick={handleClickHud8max}
          onDrop={(e) => handleDrop(e, setSelectedFileHud8max)}
          onDragOver={(e) => e.preventDefault()}
          sx={{
            marginTop: "20px",
            padding: "20px",
            border: "2px dashed",
            borderColor: "primary.main",
            borderRadius: "5px",
            textAlign: "center",
            cursor: "pointer",
            bgcolor: "background.paper",
            "&:hover": { bgcolor: "action.hover" },
          }}
        >
          <Typography variant="body1" color="textSecondary">
            Drag and drop an image here, paste, or click to select (HUD 8 Max)
          </Typography>
          {selectedFileHud8max && (
            <Box mt={2} position="relative">
              <Typography variant="subtitle1" color="primary">
                Selected File: {selectedFileHud8max.name}
              </Typography>
              {previewUrlHud8max && (
                <Box mt={1} position="relative" display="inline-block">
                  <img
                    src={previewUrlHud8max}
                    alt="Preview"
                    style={{ maxWidth: "100%", marginTop: "10px" }}
                  />
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveImage(setSelectedFileHud8max);
                    }}
                    color="secondary"
                    size="small"
                    sx={{
                      position: "absolute",
                      top: 5,
                      right: 5,
                      backgroundColor: "rgba(255,255,255,0.7)",
                      "&:hover": { backgroundColor: "rgba(255,255,255,1)" },
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              )}
            </Box>
          )}
        </Box>

        {/* Hidden file inputs */}
        <input
          accept="image/*"
          type="file"
          onChange={(e) => handleImageChange(e, setSelectedFile)}
          style={{ display: "none" }}
          ref={fileInputRef}
        />
        <input
          accept="image/*"
          type="file"
          onChange={(e) => handleImageChange(e, setSelectedFileHud6max)}
          style={{ display: "none" }}
          ref={fileInputRefHud6max}
        />
        <input
          accept="image/*"
          type="file"
          onChange={(e) => handleImageChange(e, setSelectedFileHud8max)}
          style={{ display: "none" }}
          ref={fileInputRefHud8max}
        />

        {/* Display added notes */}
        {notes.length > 0 && (
          <Box mt={4}>
            <Typography variant="h6">Notes:</Typography>
            {notes.map((note, index) => (
              <Box
                key={index}
                mt={2}
                p={2}
                border="1px solid #ccc"
                borderRadius="5px"
                position="relative"
              >
                <Typography variant="body1">{note}</Typography>
                <IconButton
                  onClick={() => handleRemoveNote(index)}
                  color="secondary"
                  size="small"
                  sx={{
                    position: "absolute",
                    top: 5,
                    right: 5,
                    backgroundColor: "rgba(255,255,255,0.7)",
                    "&:hover": { backgroundColor: "rgba(255,255,255,1)" },
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={!nickname || notes.length === 0}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddPlayerNoteModal;
