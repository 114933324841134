import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  IconButton,
  List,
  ListItem,
  Button,
} from "@mui/material";
import axios from "axios";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { hexToRgba } from "../../utils/colorUtils";
import { playerTypeColors, getPlayerTypeLabel } from "./PlayerTypes.js";
import PlayerModal from "./PlayerModal"; // Import the modal component

const getColorCode = (playerType) => {
  return playerTypeColors[playerType] || "grey";
};

const PlayerNoteCard = ({
  player,
  onEditPlayer,
  inSession,
  reFetchPlayers,
}) => {
  const token = localStorage.getItem("token");
  const apiUrl = process.env.REACT_APP_SERVER_URL || "http://localhost:5000";
  const colorCode = getColorCode(player.playerType);

  let imageSrc = null;
  if (player.imageBase64) {
    const mimeType = player.imageMimeType || "image/jpeg";
    imageSrc = `data:${mimeType};base64,${player.imageBase64}`;
  } else {
    imageSrc = "./default-profile.png";
  }

  // HUD 6 Max Image
  let imageSrcHud6max = null;
  if (player.imageHud6maxBase64) {
    const mimeTypeHud6max = player.imageHud6maxMimeType || "image/jpeg";
    imageSrcHud6max = `data:${mimeTypeHud6max};base64,${player.imageHud6maxBase64}`;
  }

  // HUD 8 Max Image (not used in lower area here)
  let imageSrcHud8max = null;
  if (player.imageHud8maxBase64) {
    const mimeTypeHud8max = player.imageHud8maxMimeType || "image/jpeg";
    imageSrcHud8max = `data:${mimeTypeHud8max};base64,${player.imageHud8maxBase64}`;
  }

  const updateOrCreateSession = async (PlayerDataId) => {
    try {
      const response = await axios.put(
        `${apiUrl}/api/session`,
        { PlayerDataId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Response from server:", response.data);
    } catch (error) {
      console.error(
        "Error updating/creating session:",
        error.response?.data || error.message
      );
    }
  };

  const removePlayerDataFromSession = async (playerDataId) => {
    try {
      await axios.delete(`${apiUrl}/api/session/playerdata/${playerDataId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error(
        "Error removing PlayerData from session:",
        error.response?.data || error.message
      );
    }
  };

  const handleAddToSessionClick = () => {
    if (inSession) {
      (async () => {
        await removePlayerDataFromSession(player._id);
        reFetchPlayers();
      })();
    } else {
      updateOrCreateSession(player._id);
    }
  };

  const handleEditClick = () => {
    onEditPlayer(player);
  };

  // Modal is now triggered by clicking the lower area
  const [modalOpen, setModalOpen] = useState(false);
  const handleLowerAreaClick = () => {
    setModalOpen(true);
  };

  const playerTypeLabel = getPlayerTypeLabel(player.playerType);

  return (
    <>
      <Card
        sx={{
          maxWidth: 355,
          height: 345,
          padding: 1,
          marginLeft: 1,
          marginRight: 1,
        }}
      >
        <Box display="flex" sx={{ maxWidth: 355 }}>
          {imageSrc && (
            <Box
              component="img"
              src={imageSrc}
              alt={`${player.translatedNickname} screenshot`}
              sx={{
                width: "155px",
                height: "100px",
                objectFit: "cover",
              }}
            />
          )}
          {/* Container for Grind and Edit buttons */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "25px",
              gap: 1,
              alignItems: "center",
              justifyContent: "center",
              width: "100px",
              height: "100px",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              size="medium"
              onClick={handleAddToSessionClick}
              sx={{
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                color: "white",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                },
              }}
            >
              Grind
            </Button>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              size="medium"
              onClick={handleEditClick}
              sx={{
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                color: "white",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                },
              }}
            >
              Edit
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            backgroundColor: hexToRgba(colorCode, 0.5),
            width: "100%",
            padding: 0.2,
          }}
        >
          <Typography variant="body1" color="primary" sx={{ marginBottom: 0 }}>
            {playerTypeLabel}
          </Typography>
        </Box>

        <Box p={0}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography variant="body2" color="text.secondary">
                {player.nickname} : {player.translatedNickname}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Typography variant="body2" color="text.secondary" sx={{ mr: 1 }}>
                {player.clicks}
              </Typography>
              <IconButton>
                <FavoriteIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>

        {/* Lower area: clickable to open the modal */}
        <Box onClick={handleLowerAreaClick} sx={{ cursor: "pointer" }}>
          {imageSrcHud6max ? (
            <CardContent sx={{ padding: 0 }}>
              <Box
                component="img"
                src={imageSrcHud6max}
                alt="Player full image"
                sx={{ width: "100%", height: "auto" }}
              />
            </CardContent>
          ) : (
            <CardContent
              sx={{
                border: 0,
                borderBlockStyle: "dotted",
                minHeight: 135,
                maxHeight: 450,
                overflow: "auto",
              }}
            >
              <List dense sx={{ marginTop: -2, marginLeft: -1 }}>
                {player.notes.map((note, index) => (
                  <ListItem key={index} sx={{ p: 0, marginLeft: -1 }}>
                    <Typography variant="body1" color="text.secondary">
                      {note}
                    </Typography>
                  </ListItem>
                ))}
              </List>
            </CardContent>
          )}
        </Box>
      </Card>

      {/* Modal triggered by clicking the lower area */}
      <PlayerModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        player={player}
        title="Player Details"
      />
    </>
  );
};

export default PlayerNoteCard;
