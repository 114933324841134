import React from "react";
import {
  CardContent,
  Typography,
  List,
  ListItem,
  Modal,
  Box,
} from "@mui/material";

const PlayerModal = ({ open, onClose, player, title, description }) => {
  // Build image sources if available.
  let playerImage = null;
  if (player && player.imageBase64) {
    const mimeType = player.imageMimeType || "image/jpeg";
    playerImage = `data:${mimeType};base64,${player.imageBase64}`;
  }

  let hud6MaxImage = null;
  if (player && player.imageHud6maxBase64) {
    const mimeType = player.imageHud6maxMimeType || "image/jpeg";
    hud6MaxImage = `data:${mimeType};base64,${player.imageHud6maxBase64}`;
  }

  let hud8MaxImage = null;
  if (player && player.imageHud8maxBase64) {
    const mimeType = player.imageHud8maxMimeType || "image/jpeg";
    hud8MaxImage = `data:${mimeType};base64,${player.imageHud8maxBase64}`;
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        onClick={onClose}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          maxWidth: "90%",
          maxHeight: "90%",
          overflowY: "auto",
          cursor: "pointer",
        }}
      >
        {/* Subtle Title */}
        <Typography
          id="modal-title"
          variant="h5"
          component="h2"
          sx={{ fontWeight: "normal", color: "text.secondary", mb: 2 }}
        >
          {player
            ? `${player.nickname} : ${player.translatedNickname}`
            : title || "Player Details"}
        </Typography>

        {/* Images Section */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            mb: 2,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {playerImage && (
            <Box
              component="img"
              src={playerImage}
              alt="Player Image"
              sx={{
                width: "100%",
                maxWidth: "150px",
                height: "auto",
                objectFit: "cover",
                borderRadius: 1,
              }}
            />
          )}
          {hud6MaxImage && (
            <Box
              component="img"
              src={hud6MaxImage}
              alt="HUD 6 Max Image"
              sx={{
                width: "100%",
                maxWidth: "500px",
                minWidth: "400px", // Force a minimum width
                height: "auto",
                objectFit: "cover",
                borderRadius: 1,
              }}
            />
          )}
          {hud8MaxImage && (
            <Box
              component="img"
              src={hud8MaxImage}
              alt="HUD 8 Max Image"
              sx={{
                width: "100%",
                maxWidth: "500px",
                minWidth: "400px", // Force a minimum width
                height: "auto",
                objectFit: "cover",
                borderRadius: 1,
              }}
            />
          )}
        </Box>

        {/* Notes Section */}
        {player ? (
          <CardContent
            sx={{
              border: 0,
              borderBlockStyle: "dotted",
              mb: 2,
            }}
          >
            <List dense sx={{ marginTop: 0, marginLeft: 0 }}>
              {player.notes.map((note, index) => (
                <ListItem key={index} sx={{ py: 1 }}>
                  <Typography variant="body1" sx={{ fontSize: "1.5rem" }}>
                    {note}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </CardContent>
        ) : (
          <Typography id="modal-description" sx={{ mt: 2, fontSize: "1.2rem" }}>
            {description || "No player data available."}
          </Typography>
        )}
      </Box>
    </Modal>
  );
};

export default PlayerModal;
